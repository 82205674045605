////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useEffect } from "react";
import { MainImage } from "@ibiliaze/reactstrap";
import { useLocation } from "react-router-dom";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from "../Dashboard/About";
import Reviews from "../Dashboard/Reviews";
import Partners from "../Dashboard/Partners";
import Copy from "../Dashboard/Copy";
import Banner from "../Dashboard/Banner";
import Albums from "../Dashboard/Albums";
import Comparisons from "../Dashboard/Comparisons";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => {
  // Location
  const location = useLocation();

  // Lifecycle hooks
  useEffect(() => {
    const scrollToHashSection = () => {
      if (location.hash) {
        const sectionId = location.hash.replace("#", "");
        const section = document.getElementById(sectionId);

        // Introduce a delay before scrolling
        if (section) {
          setTimeout(() => {
            section.scrollIntoView({ behavior: "smooth" });
          }, 500); // Delay of 500ms (you can adjust this)
        }
      }
    };

    // Scroll to the section on page load
    scrollToHashSection();

    // Add hashchange event listener to respond to changes
    window.addEventListener("hashchange", scrollToHashSection, true);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("hashchange", scrollToHashSection, true);
    };
  }, [location]);

  // JSX
  return (
    <>
      <MainImage {...c.mainPics.main} />
      <Banner />
      <About />
      <Comparisons />
      <Partners />
      <Albums />
      <Reviews />
      <Copy />
    </>
  );
};

export default HomePage;
