///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { msToHm } from "@ibiliaze/time";
import { CustomNav, Offcanvas, OffcanvasHeader, OffcanvasBody, Table } from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { logout } from "../../actions/admin";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import scroll from "../../utils/scroll";
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ admin, logout }) => {
  // State
  const [offcanvas, setOffcanvas] = useState(false);

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <CustomNav
        dark={c.nav.dark}
        blur={c.nav.blur}
        navbarBrand={c.name}
        navbarBrandHref="#"
        navbarBrandOnClick={(_) => navigate("/")}
        fadeOnPaths={c.nav.fadeOnPaths}
        navlinks={[
          {
            title: "About",
            href: "#",
            onClick: (_) => {
              navigate("/");
              scroll(document.getElementById("about-section")?.offsetTop);
            },
            hide: false,
          },
          {
            title: "Contact",
            href: "#",
            onClick: (_) => {
              navigate("/");
              window.scrollTo(0, document.body.scrollHeight);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          // Guest links
          {
            title: "Visit us",
            href: "#",
            onClick: toggleOffcanvas,
            hide: false,
          },
          {
            title: "Login",
            href: "#",
            onClick: (_) => {
              navigate("/auth");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: admin?.isAuthenticated,
          },

          // Auth links
          {
            title: admin && admin?.admin ? `${admin?.admin?.name}@${c.name}` : "",
            href: "#",
            hide: !admin?.isAuthenticated,
            onClick: (_) => {
              if (admin?.isAuthenticated) {
                navigate("/admin");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 1);
              }
            },
          },
          {
            title: "Logout",
            href: "#",
            hide: !admin?.isAuthenticated,
            onClick: (_) => {
              if (admin?.isAuthenticated) {
                logout();
              }
            },
          },
        ]}
      />
      <Offcanvas direction="end" fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Working Hours</OffcanvasHeader>
        <OffcanvasBody>
          <Table borderless>
            <tbody>
              {Object.keys(c.workingHrs.days).map((dow, i) => {
                const wh = c.workingHrs.days[dow];
                return (
                  <tr key={i}>
                    <th scope="row">{dow}</th>
                    <td>{wh.start === wh.end ? "Closed" : msToHm(wh.start)}</td>
                    <td>{wh.start === wh.end ? "Closed" : msToHm(wh.end)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, { logout })(Header);
