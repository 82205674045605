///////////////////////////////////////////////////////////////////////////////////MODULES
import { Footer as CustomFooter } from "@ibiliaze/reactstrap";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import { resolveHost } from "../../utils/resolveEnv";
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = () => (
  <CustomFooter
    title={c.name}
    textButtons={[
      {
        title: `📞 ${c.tel.tel1}`,
        onClickHandler: (_) => (window.location.href = `tel:${c.tel.tel1}`),
      },
      {
        title: `📞 ${c.tel.tel2}`,
        onClickHandler: (_) => (window.location.href = `tel:${c.tel.tel2}`),
      },
      {
        title: `📧 ${c.email.email1}`,
        onClickHandler: (_) =>
          (window.location.href = `mailto:${c.email.email1}`),
      },
      {
        title: `📍 ${c.extras.locations}`,
        onClickHandler: (_) =>
          window.open(`https://www.google.com/search?q=${c.extras.locations}`),
      },
    ]}
    navLinks={[
      {
        title: "Privacy Policy",
        href: resolveHost() + "/privacy-policy",
      },
      {
        title: "Terms & Conditions",
        href: resolveHost() + "/terms-conditions",
      },
      {
        title: "Disclaimer",
        href: resolveHost() + "/disclaimer",
      },
    ]}
  />
);

export default Footer;
