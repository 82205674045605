///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  ButtonGroup,
  Spinner,
  Form,
  ListGroupItemHeading,
  CustomListGroup,
  CustomListGroupItem,
  InputGroup,
} from "@ibiliaze/reactstrap";
import { Api } from "@ibiliaze/react-base";
import { FullPage, Section } from "@ibiliaze/react-base";
import { saveAs } from "file-saver";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "../../actions/alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
import getRandomString from "../../utils/random";
import c from "../../utils/constants";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Quote from "../Layout/Quote";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const AdminPage = ({ setAlert }) => {
  // Ref
  const componentRef = useRef();

  // State
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [generated, setGenerated] = useState("");
  const [quoteUrl, setQuoteUrl] = useState("");
  const [details, setDetails] = useState([]);
  const [inputs, setInputs] = useState({
    model: "gpt-3.5-turbo-0125",
    temprature: 1,
    top_p: 0.5,
    max_tokens: 1400,
    name: "",
    address: "",
    postcode: "",
    time: "",
    details: "",
    email: "",
  });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onGenerateClick = async (_) => {
    try {
      setLoading(true);
      const res = await api.post("/api/quote/generate", { ...inputs });
      setGenerated(res.data.quote);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const onAddClick = (_) => {
    try {
      setDetails((c) => [...c, { input: inputs.details, details: generated }]);

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit(false);
    } catch (e) {
      setEdit(false);
      console.error(e);
    }
  };

  const onDetailClick = (det, i) => {
    try {
      setEdit(i + 1);
      setInputs((c) => ({ ...c, details: det.input }));
      setGenerated(det.details);
    } catch (e) {
      setEdit(false);
      console.error(e);
    }
  };

  const onDeleteClick = (_) => {
    try {
      const arr = details;
      arr.splice(edit - 1, 1);
      setDetails([...arr]);

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit(false);
    } catch (e) {
      setEdit(false);
      console.error(e);
    }
  };

  const onSaveClick = async (_) => {
    try {
      setLoading(true);
      const res = await api.post("/api/quote", {
        ...inputs,
        dom: componentRef.current.innerHTML,
      });

      const fileUrl = res.data.url;
      const fileName = `Quote for ${inputs.name} - ${inputs.postcode}`;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      saveAs(blob, fileName);

      setQuoteUrl(fileUrl);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setQuoteUrl("");
      console.error(e);
    }
  };

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!quoteUrl) {
        return setAlert("Quote has not been created", true, null);
      }

      setLoading(true);
      const res = await api.post("/api/quote/send", {
        to: inputs.email,
        name: inputs.name,
        attachments: c.extras.attachments,
        quoteUrl,
      });

      setAlert(res.data.message, false, null);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Form onSubmit={onSubmit}>
          <FormGroup floating>
            <CustomInput
              required
              placeholder="Model *"
              type="select"
              name="model"
              value={inputs.model}
              onChange={onInputsChange}
            >
              <option>gpt-3.5-turbo-0125</option>
              <option>gpt-4</option>
            </CustomInput>
            <Label>Model *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Temprature *"
              type="number"
              name="temprature"
              value={inputs.temprature}
              onChange={onInputsChange}
            />
            <Label>Temprature *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Top P *"
              type="number"
              name="top_p"
              value={inputs.top_p}
              onChange={onInputsChange}
            />
            <Label>Top P *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Max Tokens *"
              type="number"
              name="max_tokens"
              value={inputs.max_tokens}
              onChange={onInputsChange}
            />
            <Label>Max Tokens *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Name *"
              type="text"
              name="name"
              value={inputs.name}
              onChange={onInputsChange}
            />
            <Label>Name *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Address *"
              type="text"
              name="address"
              value={inputs.address}
              onChange={onInputsChange}
            />
            <Label>Address *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Postcode *"
              type="text"
              name="postcode"
              value={inputs.postcode}
              onChange={onInputsChange}
            />
            <Label>Postcode *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Estimated Time *"
              type="text"
              name="time"
              value={inputs.time}
              onChange={onInputsChange}
            />
            <Label>Estimated Time *</Label>
          </FormGroup>

          <br />
          <CustomListGroup>
            {details.map((det, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onDetailClick(det, i)}>
                <ListGroupItemHeading>{det.details.header}</ListGroupItemHeading>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>
          <br />

          <FormGroup>
            <Label>Details</Label>
            <CustomInput
              placeholder="Details"
              type="textarea"
              rows={10}
              name="details"
              value={inputs.details}
              onChange={onInputsChange}
            />
          </FormGroup>

          {!!generated && typeof generated === "object" ? (
            <pre>
              {JSON.stringify(generated, null, 2)}
              <br />
            </pre>
          ) : (
            <></>
          )}

          <ButtonGroup>
            <CustomButton disabled={loading ? true : false} color="primary" black onClick={onGenerateClick}>
              {loading ? <Spinner type="grow" size="sm" /> : "Generate"}
            </CustomButton>
            <CustomButton color="primary" onClick={onAddClick}>
              Add to Quote
            </CustomButton>
            {!!edit ? (
              <>
                <CustomButton color="danger" onClick={onDeleteClick}>
                  Delete
                </CustomButton>
              </>
            ) : (
              <></>
            )}
          </ButtonGroup>

          <br />
          <br />

          <CustomButton disabled={loading ? true : false} color="primary" black onClick={onSaveClick}>
            {loading ? <Spinner type="grow" size="sm" /> : "CREATE QUOTE"}
          </CustomButton>
          <br />
          <br />

          <InputGroup>
            <CustomInput
              required
              type="text"
              name="email"
              value={inputs.email}
              onChange={onInputsChange}
              placeholder="recipient@example.com"
            />
            <CustomButton
              disabled={loading ? true : !quoteUrl ? true : false}
              color="primary"
              black
              type="submit"
            >
              {loading ? <Spinner type="grow" size="sm" /> : "Email Quote"}
            </CustomButton>
          </InputGroup>
          <br />
          <br />

          <div className="p-m" style={{ background: "white" }}>
            <div ref={componentRef}>
              <Quote details={details} inputs={inputs} id={getRandomString(4)} />
            </div>
          </div>
        </Form>
      </Section>
    </FullPage>
  );
};

export default connect(null, {
  setAlert,
})(AdminPage);
