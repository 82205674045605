///////////////////////////////////////////////////////////////////////////////////MODULES
import { MainImage, Row, Col } from "@ibiliaze/reactstrap";
import { FadeIn, TransformYDown } from "@ibiliaze/react-intersect";
import { Section } from "@ibiliaze/react-base";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const SecondaryAbout = () => {
  // JSX
  return (
    <>
      <TransformYDown>
        <div className="p-t-xl p-b-xl">
          <MainImage {...c.mainPics.copy1} />
        </div>
      </TransformYDown>
      <Section className="custom-page p-t-xl p-b-xl">
        <FadeIn>
          <h1 className="t-align-c">
            You're in <span className="text-gradient">Safe Hands</span>
          </h1>
        </FadeIn>
        <Row>
          {c.infos.infos3.map((info, i) => (
            <Col md={6} key={i}>
              <TransformYDown>
                <h3>
                  {info.header}
                  <span>{info.icon()}</span>
                </h3>
                <p>{info.body}</p>
              </TransformYDown>
            </Col>
          ))}
        </Row>
      </Section>
      <br />
    </>
  );
};

export default SecondaryAbout;
