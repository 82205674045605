///////////////////////////////////////////////////////////////////////////////////MODULES
import { Fragment } from "react";
import { Col, CustomButton, Row } from "@ibiliaze/reactstrap";
import { scroll, Section } from "@ibiliaze/react-base";
import CompareImage from "react-compare-image";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const PictureReveal = () => {
  return (
    <Section className="custom-page p-t-xl p-b-xl">
      <Row>
        {c.infos.infos2.map((info, i) => (
          <Fragment key={i}>
            <Col md={8} className="p-b-l">
              <h2>{info.header}</h2>
              <p>{info.body}</p>
              <CustomButton
                color="primary"
                onClick={(_) => window.open(`https://wa.me/${c.tel.tel2}`)}
              >
                Get your FREE Quote
              </CustomButton>
              <br />
              <br />
              <CustomButton
                black
                color="primary"
                onClick={(_) =>
                  scroll(document.getElementById("album-section")?.offsetTop)
                }
              >
                EXPLORE
              </CustomButton>
            </Col>
            <Col md={4} className="p-b-l">
              <CompareImage
                leftImage={info.beforePic}
                rightImage={info.afterPic}
              />
            </Col>
          </Fragment>
        ))}
      </Row>
    </Section>
  );
};

export default PictureReveal;
