///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
//////////////////////////////////////////////////////////////////////////////////////////

const hStyles = {
  color: "rgb(66, 77, 95)",
};

const textStyles = {
  fontFamily: "Segoe UI, sans-serif",
  fontSize: "14px",
  fontWeight: 550,
  color: "rgb(37, 37, 37)",
};

const hrStyles = {
  backgroundColor: "#d8d8d8",
  border: "none",
  display: "block",
  height: "2px",
  marginLeft: "auto",
  marginRight: "auto",
  width: "60%",
};

const trStyles = {
  background: "#eeeeff",
};

const Quote = ({ details, inputs, id }) => {
  // State
  const [total, setTotal] = useState(0);

  // Lifecycle Hooks
  useEffect(() => {
    try {
      let totalPrice = 0;

      details?.forEach((quote) => {
        const price = Number(quote.details.price.replace("£", "").replace(",", ""));
        totalPrice += price;
      });

      setTotal(totalPrice);
    } catch (e) {
      console.error(e);
    }
  }, [details]);

  // JSX
  return (
    <div
      style={{
        background: "white",
        ...textStyles,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img
          src="https://res.cloudinary.com/dgyewim8j/image/upload/v1728265459/Webify%20Lake/London%20Mile/1_zcyjhr.png"
          width="190px"
          height="190px"
        />
        <div>
          <div style={{ fontSize: "26px", ...hStyles }}>London Mile LTD</div>
          <div>4 Dereham Road</div>
          <div>IG11 9HA</div>
          <div>London</div>
          <div>United Kingdom</div>
          <br />
          <div>info@londonmile.co.uk</div>
          <div>www.londonmile.co.uk</div>
        </div>
        <div>
          <div style={{ fontSize: "26px", ...hStyles }}>QUOTE</div>
          <div>
            Date:{" "}
            {new Date().toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })}
          </div>
          <div>
            Reference: <b>{id}</b>
          </div>
        </div>
      </div>

      <br />
      <hr style={hrStyles} />
      <br />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <div style={{ fontSize: "large" }}>Quote to:</div>
          <div>{inputs.name}</div>
          <div>{inputs.address}</div>
          <div>{inputs.postcode}</div>
          <div>United Kingdom</div>
        </div>
      </div>

      <table
        borderless
        style={{ borderCollapse: "separate", borderSpacing: "0 40px", width: "100%", ...textStyles }}
      >
        <thead>
          <tr style={trStyles}>
            <th>
              <div style={{ textAlign: "left", padding: "10px", ...textStyles, fontSize: "17px" }}>
                Description
              </div>
            </th>
            <th style={{ textAlign: "right", padding: "10px", ...textStyles, fontSize: "17px" }}>
              <div>Rate</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {!!details &&
            Array.isArray(details) &&
            details.map((det, i) => (
              <tr key={i}>
                <td>
                  <div style={{ textAlign: "center", fontSize: "17px", ...hStyles, paddingBottom: "10px" }}>
                    {det?.details?.header}
                  </div>
                  {det?.details?.quote?.map((line, i) => (
                    <li key={i}>{line}</li>
                  ))}
                </td>
                <td style={{ textAlign: "right", verticalAlign: "top" }}>
                  <b>{det?.details?.price}.00</b>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div style={{ padding: "10px", ...trStyles, fontSize: "large", textAlign: "right" }}>
        Quote total: £{total}.00
      </div>

      <br />
      <br />

      <div style={{ textAlign: "center", fontSize: "20px", ...hStyles }}>Terms and Conditions</div>
      <br />
      <li>
        London Mile will issue a Warranty Certificate upon completing the project date that is valid for 1
        year
      </li>
      <li>Rubbish collection is included in this quote</li>
      <li>
        Visible materials should be provided by the customer. However, London Mile can assist in sourcing
        visible materials with trade discounts, if preferred
      </li>
      <li>The entire project is estimated to be completed within {inputs.time}</li>

      <br />
      <br />
      <div style={{ textAlign: "center", fontSize: "20px", ...hStyles }}>Payment Terms</div>
      <br />
      <li>The payment will be split into 3 instalments, after the deposit is paid</li>
      <li>Your first payment must be made on the 2nd day of the project</li>
    </div>
  );
};

export default Quote;
