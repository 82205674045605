///////////////////////////////////////////////////////////////////////////////////MODULES
import Marquee from "react-fast-marquee";
import { FadeIn } from "@ibiliaze/react-intersect";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Partners = () => (
  <>
    <section className="page p-t-xl p-b-xl">
      <FadeIn>
        <h1 className="t-align-c">Our Partners</h1>
        <br />
        <h4 className="t-align-c">
          Get Discounts From Our{" "}
          <span className="text-gradient">Reputable Partners</span>
        </h4>
      </FadeIn>
      <hr />
    </section>
    <Marquee speed={40} gradientWidth={50} gradient={false}>
      {c.extras.partners.map((partner, i) => (
        <img
          key={i}
          src={partner.logoSrc}
          alt="images"
          className="marquee_img"
        />
      ))}
    </Marquee>
    <br />
  </>
);

export default Partners;
