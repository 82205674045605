export const config = ({ scroll = () => {}, IOS17Filled = () => {} }) => {
  const configObj = {
    // GENERAL INFO
    name: "EV Constructing",
    domain: "evconstructing.co.uk",
    slogan: "EVolution of Construction",

    // CONTACT
    tel: { tel1: "07718216555", tel2: "07955555501" },
    email: { email1: "info@evconstructing.co.uk" },

    // CHAT
    chat: {
      showWidget: true,
      avatar: "/img/chat.jpg",
      person: "Vugar",
      tel: "07718216555",
      message: `Hello there! \n \nHow can we help?`,
      status: `Typically replies within a few minutes`,
    },

    // SOCIALS
    socials: {
      instagram: "evconstructing",
    },

    // TYPE EFFECTS
    textType: ["Bathrooms", "Kitchens", "Extensions", "Demolition", "Plumbing", "Heating", "Pipe Work"],

    // HEADER
    nav: {
      blur: "1px",
      logo: "/img/logo-white.png",
      dark: true,
      styles: { height: "40px" },
      fadeOnPaths: ["/"],
    },

    // MAIN PIC
    mainPics: {
      main: {
        carousel: [
          {
            src: "/img/main/1.jpg",
            altText: "Kitching Fitting",
            key: 1,
          },
          {
            src: "/img/main/2.jpg",
            altText: "Bathroom Fitting",
            key: 2,
          },
          {
            src: "/img/main/3.jpg",
            altText: "Living Rooms",
            key: 3,
          },
        ],
      },
      copy1: {
        imgPath: "/img/copy/1.jpg",
        parallax: false,
        noShadow: true,
        imgBottom: "0%",
        sloganCenter: true,
        slogan: "Commercial & Domestion Trade You Can Trust!",
      },
    },

    // WORKING HOURS
    workingHrs: {
      days: {
        Mon: { start: 32400000, end: 64800000 },
        Tue: { start: 32400000, end: 64800000 },
        Wed: { start: 32400000, end: 64800000 },
        Thu: { start: 32400000, end: 64800000 },
        Fri: { start: 32400000, end: 64800000 },
        Sat: { start: 32400000, end: 64800000 },
        Sun: { start: 0, end: 0 },
      },
      interval: 1800000,
    },

    // INFOS
    infos: {
      infos1: [
        {
          header: "Commercial Work",
          body: "We have invested in all aspects to ensure that we offer a market leading approach to design and build",
          icon: (_) => IOS17Filled({ name: "building", size: "50" }),
          img: "/img/infos/infos1-1.jpg",
          alt: "infos1-1",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Domestic Work",
          body: "Highly professional company with a passion for innovative designs and quality construction. We believe strongly in understanding the needs of our customers, and our business is developed specifically to meet those needs.",
          icon: (_) => IOS17Filled({ name: "home", size: "50" }),
          img: "/img/infos/infos1-2.jpg",
          alt: "infos1-1",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Free Estimates",
          body: "Get your free quote today. Our team are always ready to help. Talk to us or call us directly for an immediate quote.",
          icon: (_) => IOS17Filled({ name: "money", size: "50" }),
          img: "/img/infos/infos1-3.jpg",
          alt: "infos1-1",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
      ],
      infos2: [
        {
          beforePic: "/img/comparisons/before1.webp",
          afterPic: "/img/comparisons/after1.webp",
          header: "Bathrooms",
          body: "Discover our bathroom renovation portfolio, featuring elegant transformations that blend style and functionality. From luxurious tile work and custom vanities to modern fixtures and lighting, this album highlights our expertise in creating beautiful, spa-like spaces. Witness the attention to detail and quality craftsmanship in each project.",
        },
        {
          beforePic: "/img/comparisons/before2.avif",
          afterPic: "/img/comparisons/after2.avif",
          header: "Kitchens",
          body: "Explore our latest kitchen renovation projects, showcasing stunning results. From custom cabinetry and sleek countertops to modern lighting and tiling, this album highlights the craftsmanship, attention to detail, and innovative design that define our work.",
        },
      ],
      infos3: [
        {
          header: "Warranty Included",
          body: "We take pride in the quality of our work and the satisfaction of our clients. That`'s why we offer a 6-month warranty on all of our construction projects. You can trust that the work we do for you will be completed to the highest standards and will meet your expectations. If any issues arise within 12 months of completion, we will work to quickly resolve them and ensure your complete satisfaction.",
          icon: (_) => IOS17Filled({ name: "book", size: "40" }),
        },
        {
          header: "Insurance",
          body: "The safety and security of our clients and workers are our top priorities. That's why we take all necessary steps to ensure we are fully insured against any potential accidents or damages. We have comprehensive liability insurance as well as workers' compensation insurance to protect our clients and our team in case of any unforeseen events. We are fully committed to providing you with the highest standards of safety, quality and customer service.",
          icon: (_) => IOS17Filled({ name: "hearts", size: "40" }),
        },
      ],
    },

    // SERVICES
    services: {
      currency: "£",
      serviceGroups: [
        {
          serviceGroupName: "Bathrooms",
          serviceItems: [
            {
              serviceName: "Bathroom Cladding",
              id: "Bathroom Cladding",
              imageSrc: "/img/work/BathroomCladding.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 50,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Bathroom Designer",
              id: "Bathroom Designer",
              imageSrc: "/img/work/BathroomDesigner.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Bathroom Fitter",
              id: "Bathroom Fitter",
              imageSrc: "/img/work/BathroomFitter.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 80,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Bathroom Supplier",
              id: "Bathroom Supplier",
              imageSrc: "/img/work/BathroomSupplier.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 60,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Wet Rooms",
              id: "Wet Rooms",
              imageSrc: "/img/work/WetRooms.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 60,
              duration: "00:15",
              courses: 6,
            },
          ],
        },
        {
          serviceGroupName: "Kitchens",
          serviceItems: [
            {
              serviceName: "Acrylic Worktops",
              id: "Acrylic Worktops",
              imageSrc: "/img/work/AcrylicWorktops.jpg",
              description: "Includes the chest area and stomach",
              price: 60,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Kitchen Doors",
              id: "Kitchen Doors",
              imageSrc: "/img/work/KitchenDoors.jpg",
              description: "Includes all parts of the body",
              price: 360,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Kitchen Fitters",
              id: "Kitchen Fitters",
              imageSrc: "/img/work/KitchenFitters.jpg",
              description: "Includes: hollywood, arms, legs, underarms",
              price: 220,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Kitchen Refurbishments",
              id: "Kitchen Refurbishments",
              imageSrc: "/img/work/KitchenRefurbishments.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Kitchen Supplier",
              id: "Kitchen Supplier",
              imageSrc: "/img/work/KitchenSupplier.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Laminate Worktops",
              id: "Laminate Worktops",
              imageSrc: "/img/work/LaminateWorktops.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Marble Worktops",
              id: "Marble Worktops",
              imageSrc: "/img/work/MarbleWorktop.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Painting Kitchen Cabinets",
              id: "Painting Kitchen Cabinets",
              imageSrc: "/img/work/PaintingKitchenCabinets.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Replacement Doors and Drawers",
              id: "Replacement Doors and Drawers",
              imageSrc: "/img/work/ReplacementDoorsandDrawers.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Solid Wood Worktops",
              id: "Solid Wood Worktops",
              imageSrc: "/img/work/SolidWoodWorktops.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Worktops",
              id: "Worktops",
              imageSrc: "/img/work/Worktops.jpg",
              description:
                "Includes the essentials: lip, chin, jawline, earlobes, nostrils, nipples, navel pubes, peri anal",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
          ],
        },
        {
          serviceGroupName: "Bricklayer",
          serviceItems: [
            {
              serviceName: "Brickwork",
              id: "Brickwork",
              imageSrc: "/img/work/Brickwork.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 25,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Repointing",
              id: "Repointing",
              imageSrc: "/img/work/Repointing.jpg",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
              price: 15,
              duration: "00:15",
              courses: 6,
            },
          ],
        },
        {
          serviceGroupName: "Groundwork",
          serviceItems: [
            {
              serviceName: "Piling",
              id: "Piling",
              imageSrc: "/img/work/Piling.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
          ],
        },

        {
          serviceGroupName: "Builder",
          serviceItems: [
            {
              serviceName: "Concrete Garages",
              id: "Concrete Garages",
              imageSrc: "/img/work/Concrete Garages.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Concreting",
              id: "Concreting",
              imageSrc: "/img/work/Concreting.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Demolition",
              id: "Demolition",
              imageSrc: "/img/work/Demolition.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Extensions/Conversions",
              id: "Extensions/Conversions",
              imageSrc: "/img/work/ExtensionsConversions.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Foundations",
              id: "Foundations",
              imageSrc: "/img/work/Foundation.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Garage Conversions",
              id: "Garage Conversions",
              imageSrc: "/img/work/GarageConversions.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Garden Studios",
              id: "Garden Studios",
              imageSrc: "/img/work/GardenStudios.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "General Building",
              id: "General Building",
              imageSrc: "/img/work/GeneralBuilding.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Heritage Property Specialist",
              id: "Heritage Property Specialist",
              imageSrc: "/img/work/HeritagePropertySpecialist.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Mastic Sealant",
              id: "Mastic Sealant",
              imageSrc: "/img/work/MasticSealant.jpeg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Partition Walls",
              id: "Partition Walls",
              imageSrc: "/img/work/PartitionWalls.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Period Property Restoration",
              id: "Period Property Restoration",
              imageSrc: "/img/work/PeriodPropertyRestoration.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Project Management",
              id: "Project Management",
              imageSrc: "/img/work/ProjectManagement.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Refurbishments",
              id: "Refurbishments",
              imageSrc: "/img/work/Refurbishments.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Repair and Listed Building Specialist",
              id: "Repair and Listed Building Specialist",
              imageSrc: "/img/work/RepairAndListedBuildingSpecialist.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Suspended Ceilings",
              id: "Suspended Ceilings",
              imageSrc: "/img/work/SuspendedCeilings.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Sustainable Construction",
              id: "Sustainable Construction",
              imageSrc: "/img/work/SustainableConstruction.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Tree Houses",
              id: "Tree Houses",
              imageSrc: "/img/work/TreeHouses.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Wall Tie Replacements",
              id: "Wall Tie Replacements",
              imageSrc: "/img/work/WallTieReplacements.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Warehouse Racking",
              id: "Warehouse Racking",
              imageSrc: "/img/work/WarehouseRacking.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
          ],
        },
        {
          serviceGroupName: "Plumber",
          serviceItems: [
            {
              serviceName: "Blocked Baths",
              id: "Blocked Baths",
              imageSrc: "/img/work/BathroomCladding.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "General Plumbing",
              id: "General Plumbing",
              imageSrc: "/img/work/GeneralPlumbing.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Plumbing Repairs",
              id: "Plumbing Repairs",
              imageSrc: "/img/work/PlumbingRepairs.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Radiators/Valves/Thermostats Installed/Replaced",
              id: "Radiators/Valves/Thermostats Installed/Replaced",
              imageSrc: "/img/work/Radiators.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Shower Installation/Repair",
              id: "Shower Installation/Repair",
              imageSrc: "/img/work/BathroomDesigner.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Tap Repair",
              id: "Tap Repair",
              imageSrc: "/img/work/TapRepair.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Under Floor Heating",
              id: "Under Floor Heating",
              imageSrc: "/img/work/UnderFloorHeating.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Water Leak Detection",
              id: "Water Leak Detection",
              imageSrc: "/img/work/WaterLeakDetection.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Water Pumps",
              id: "Water Pumps",
              imageSrc: "/img/work/WaterPumps.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
            {
              serviceName: "Water Supply Pipe Repairs",
              id: "Water Supply Pipe Repairs",
              imageSrc: "/img/work/WaterSupplyPipeRepairs.jpg",
              description: "Includes the chest area and stomach",
              price: 30,
              duration: "00:15",
              courses: 6,
            },
          ],
        },
      ],
    },

    // REVIEWS
    reviews: [
      {
        title: "Renovation and refit of bathroom",
        body: "From start to finish this was the easiest and best experience we’ve had with tradesmen in London. Work finished right on time. Men on site were on time every day; worked long and hard. Always courteous and spent a lot of time making sure they kept areas as clean and tidy as possible when they left. Work itself was to a very high standard and there were no hidden extras when it came to the final bill. Estimate was detailed and accurate. Would have zero hesitation in recommending to others or using again for further work.",
      },
      {
        title: "Refurb of whole house",
        body: "Vugar and team are an excellent, hard working team. We were impressed at the speed, quality and communication involved in the build. Vugar also provides helpful suggestions and keeps his clients informed of changes. All in all, a very positive experience.",
      },
      {
        title: "Bathroom refurbishment",
        body: "The bathroom fitters, Sergiu and Eduard, were amazing - in every way! They were professional, punctual, reliable, accommodating, polite and respectful, modest, clean and tidy, and work fast. I am a perfectionist, but their workmanship is superb - impeccable! Immaculate perfection! Vugar, the director, went the extra mile to help me choose tiles for my accent wall, and I am grateful to have benefited from his expertise and trade discount. This team is delightful to work with, with first-class qualities and workmanship. They were incredible - from start to finish! My bathroom looks stunning!",
      },
      {
        title: "Bathroom Reno / Overall Home Maintenance and Improvement",
        body: "I have had the pleasure of working with Vugar and his teams on multiple projects this past year. The works have all been amazing, the teams always provided the highest quality of finish, showed up on time, kept the work space tidy, was very respectful of my home. Vugar is attentive, easy to contact, provides fair pricing, has amazing attention to detail, prides himself on high quality structural and aesthetic finish, is open minded and finds solutions to all my odd requests. I will contact this company again for future works without a question.",
      },
      {
        title: "Creating new alcove in chimney stack and new ceiling lights",
        body: "Good professional service - both elements of work were done to a high standard and to time Would definitely use again in the future for any other work needed on our property",
      },
      {
        title: "Complete renovation of bathroom",
        body: "Vugar and his team did an amazing job renovating our bathroom. Nothing was too much hassle and they work incredibly hard. The team are invested in getting the best results throughout the job, consulting on how to get the best finish/deal with any issues that come up efficiently. Initial quote was reliable, no slippage. Left the space spotless after the job. 10/10.",
      },
      {
        title: "Building wardrobes and stripping",
        body: "This is the second time we have used London Mile for building and decorating. This time they built wardrobes from scratch and fully painted them. They also stripped old wallpaper and plaster off walls and ceiling in a large living room dining room. Replastered and repainted, including painting woodwork. The work was all completed within 8 days, they arrived promptly to start work at 8am, and worked until at least 4pm every day. They pay attention to every little detail and ensure we were happy. Done to a very high standard. Will be using again and recommending to family/friends.",
      },
    ],

    extras: {
      attachments: [
        {
          filename: "London Mile - Public Liability Insurance.pdf",
          path: "https://res.cloudinary.com/dgyewim8j/image/upload/v1728260394/Webify%20Lake/London%20Mile/Documents/London_Mile_-_Public_Liability_Insurance_zmqssa.pdf",
        },
        {
          filename: "Warranty from London Mile.pdf",
          path: "https://res.cloudinary.com/dgyewim8j/image/upload/v1728260394/Webify%20Lake/London%20Mile/Documents/Warranty_from_London_Mile_i3tthb.pdf",
        },
      ],

      locations: "London, Belvedere, Orpington, Folkestone, Kensington, Greater London",
      checkatrade: {
        name: "EV Constructing",
        urlName: "evconstructing",
        link: "https://www.checkatrade.com/trades/evconstructing",
        rating: "10/10 Rating",
      },
      partners: [
        { header: "Toolstation", logoSrc: "/img/logos/tslogo.png" },
        { header: "Wickes", logoSrc: "/img/logos/wlogo.png" },
        { header: "Howdens", logoSrc: "/img/logos/hlogo.png" },
        { header: "Toppps Tiles", logoSrc: "/img/logos/ttlogo.png" },
        { header: "Screwfix", logoSrc: "/img/logos/sflogo.png" },
        { header: "Crown", logoSrc: "/img/logos/clogo.png" },
        { header: "BQ", logoSrc: "/img/logos/bqlogo.png" },
        { header: "National Lighting", logoSrc: "/img/logos/nllogo.png" },
        { header: "Travis Perkins", logoSrc: "/img/logos/tplogo.png" },
        {
          header: "Selco Builders Warehouse",
          logoSrc: "/img/logos/sbwlogo.png",
        },
        { header: "Al-Murad Tiles", logoSrc: "/img/logos/amlogo.png" },
        { header: "KBS", logoSrc: "/img/logos/kbslogo.png" },
        { header: "Victorian Plumbing", logoSrc: "/img/logos/vp.png" },
      ],
    },
  };

  return configObj;
};
